import ComingSoonScreen from "./pages/ComingSoonScreen/ComingSoonScreen";
import HomeScreen from "./pages/HomeScreen/HomeScreen";
import ReferralScreen from "./pages/ReferralScreen/ReferralScreen";
import TasksScreen from "./pages/TasksScreen/TasksScreen";

const prefix = "";
const routes = {
  home: { path: "/" + prefix, element: <HomeScreen /> },
  tasks: { path: prefix + "/tasks", element: <TasksScreen /> },
  friends: { path: prefix + "/friends", element: <ReferralScreen /> },
  staking: { path: prefix + "/staking", element: <ComingSoonScreen /> },
  wallet: { path: prefix + "/wallet", element: <ComingSoonScreen /> },
  farmingPlans: {
    path: prefix + "farmingPlans",
    element: <ComingSoonScreen />,
  },
  playGame: {
    path: prefix + "playGame",
    element: <ComingSoonScreen />,
  },
  startLattery: {
    path: prefix + "startLattery",
    element: <ComingSoonScreen />,
  },
};

const routePath = Object.fromEntries(
  Object.entries(routes).map(([key, innerObj]) => [key, innerObj.path])
);

export { routePath };
export default routes;
