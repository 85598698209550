import React from "react";

function ActionButton({ Icon, title, titleStyle, onClickHandler, style }) {
  return (
    <div
      onClick={onClickHandler}
      className="d-flex justify-content-center align-items-center"
      style={{
        minWidth: 142,
        height: 41,
        backgroundColor: "var(--bg-green)",
        color: "#000",
        borderRadius: 25,
        cursor: "pointer",
        paddingLeft: ".5rem",
        paddingRight: ".5rem",
        ...style,
      }}
    >
      <span className="pe-2">{Icon}</span>
      <span style={{ ...titleStyle }} className="flex-grow-1">
        {title}
      </span>
    </div>
  );
}

export default ActionButton;
