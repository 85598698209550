import React from "react";
import "./ModalCard.css";
import { IoCloseCircle } from "react-icons/io5";

function ModalCard({ children, isVisible, onClose }) {
  return (
    <div className={"modal-card " + (isVisible ? "visible-modal-card" : "")}>
      <span
        onClick={onClose}
        className="btn-close-modal rounded-circle clickable d-flex justify-content-center align-items-center"
      >
        <IoCloseCircle size={35} />
      </span>
      <div className="modal-card-body">{children}</div>
    </div>
  );
}

export default ModalCard;
