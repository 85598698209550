import React from "react";

function ActionImageCard({ backgroundImage, ActionButton }) {
  return (
    <div
      className="w-100 mb-5"
      style={{
        borderRadius: 25,
        marginButtom: 52,
        height: 176,
        overflow: "hidden",
        position: "relative",
        backgroundColor: "#fff",
        backgroundImage: `url(${backgroundImage})`,
        backgroundPosition: "center center",
        backgroundSize: "cover",
      }}
    >
      {/* <img src= className="img-fluid" /> */}
      <div
        className="w-100 pe-2 d-flex justify-content-end"
        style={{
          position: "absolute",
          bottom: 6,
        }}
      >
        {ActionButton}
      </div>
    </div>
  );
}

export default ActionImageCard;
