import React, { useContext } from "react";
import { routePath } from "../../routes";
import { useNavigate } from "react-router-dom";
/** Components */
import AccountBalance from "../../components/AccountBalanceComponent/AccountBalance";
import ActionImageCard from "../../components/ActionImageCardComponent/ActionImageCard";
import ActionButton from "../../components/SharedComponents/ActionButton";
import BottomNavigation from "../../components/BottomNavigationComponent/BottomNavigation";
import FarmingButton from "../../components/FarmingButtonComponent/FarmingButton";
import Profile from "../../components/ProfileComponenet/Profile";
import Screen from "../../components/SharedComponents/Screen";

/** Icons */
import { IoChevronForwardCircle } from "react-icons/io5";

/** States and handlers */
import useHomeScreenHandlers from "./useHomeScreenHandlers";
import useNavigations from "../../hooks/useNavigations";

function HomeScreen() {
  const navigate = useNavigate();
  const navLinks = useNavigations();

  const {
    balance,
    farming,
    name,
    farmingButtonHandler,
    farmingFinishedHandler,
  } = useHomeScreenHandlers();

  return (
    <Screen
      style={{
        paddingBottom: 98,
      }}
    >
      <Profile name={name} />
      <AccountBalance balance={balance} />

      <div className="mt-5 w-100">
        <ActionImageCard
          backgroundImage={`${process.env.PUBLIC_URL}/images/game-widget.jpg`}
          ActionButton={
            <ActionButton
              title={"Play now!"}
              Icon={<IoChevronForwardCircle />}
              titleStyle={{ textAlign: "center" }}
              onClickHandler={() => navigate(routePath.playGame)}
            />
          }
        />

        <ActionImageCard
          backgroundImage={`${process.env.PUBLIC_URL}/images/lattery-widget.jpg`}
          ActionButton={
            <ActionButton
              title={"Start now!"}
              Icon={<IoChevronForwardCircle />}
              titleStyle={{ textAlign: "center" }}
              onClickHandler={() => navigate(routePath.startLattery)}
            />
          }
        />
      </div>

      <FarmingButton
        onFinished={farmingFinishedHandler}
        onClickHandler={farmingButtonHandler}
        farming={farming}
      />
      <BottomNavigation navLinks={navLinks} />
    </Screen>
  );
}

export default HomeScreen;
