import React from "react";
import { useNavigate } from "react-router-dom";
import { routePath } from "../../routes";

import Screen from "../../components/SharedComponents/Screen";
import ActionButton from "../../components/SharedComponents/ActionButton";
import { IoArrowBackCircleOutline } from "react-icons/io5";

function ComingSoonScreen() {
  const navigate = useNavigate();

  return (
    <Screen
      style={{
        padding: 0,
        justifyContent: "center",
        backgroundColor: "#00CDA0",
      }}
    >
      <img
        className="w-100 img-fluid"
        src={`${process.env.PUBLIC_URL}/images/coming-soon-screen.jpg`}
      />

      <ActionButton
        style={{
          backgroundColor: "var(--bg-red)",
          color: "#fff",
          paddingLeft: "1rem",
          paddingRight: "1rem",
        }}
        titleStyle={{ textAlign: "center" }}
        Icon={<IoArrowBackCircleOutline />}
        title="Back to Home"
        onClickHandler={() => navigate(routePath.home)}
      />
    </Screen>
  );
}

export default ComingSoonScreen;
