import "./Profile.css";
import React from "react";
import { useNavigate } from "react-router-dom";
import ActionButton from "../SharedComponents/ActionButton";
import { GiStarFormation } from "react-icons/gi";
import { routePath } from "../../routes";

const Profile = ({ name }) => {
  const navigate = useNavigate();

  return (
    <div className="profile w-100 d-flex justify-content-between align-items-center">
      <div className="avatar-container d-flex align-items-center">
        <div className="profile-pic">
          <img
            src={`${process.env.PUBLIC_URL}/images/avatar.png`}
            alt="profile"
          />
        </div>
        <h5 className="profile-name text-white">{name}</h5>
      </div>
      <ActionButton
        titleStyle={{ textAlign: "center" }}
        title={"Upgrade"}
        Icon={<GiStarFormation />}
        onClickHandler={() => navigate(routePath.farmingPlans)}
      />
    </div>
  );
};

export default Profile;
