import moment from "moment";
import { useContext, useEffect, useState } from "react";
import config from "../../config";

import { BalanceContext } from "../../contexts/BalanceContext";
import useApiRequest from "../../hooks/useApiRequest";
import useNotifications from "../../hooks/useNotifications";

function useTasksScreenHandlers() {
  /** States */
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState([]);
  const [selectedTask, setSelectedTask] = useState(null);
  const [isVisibleTaskModal, setIsVisibleTaskModal] = useState(false);
  /** Hooks */
  const { updateBalance, balance } = useContext(BalanceContext);
  const { notifications, sendNotification } = useNotifications();
  const { isLoading, sendRequest } = useApiRequest(sendNotification);

  /** fetchTasksData */
  const onSuccessFetchTasksData = async ({ success, data }) => {
    if (success === false) {
      sendNotification("Something went wrong. Page loading failed.", "Error");
      return;
    }

    let updatedCompletedTasks = [];
    let updatedTasks = [];

    // combine tasks and user tasks fields.
    for (let task of data.tasks) {
      const userTask = data.user_tasks.find(
        (userTask) => task.id === userTask.id
      );

      if (userTask !== undefined) {
        task = { ...task, ...userTask };

        // task has been trying to do by user later.
        if (userTask.status === "Completed" || userTask.status === "Claimed")
          updatedCompletedTasks = [...updatedCompletedTasks, task];
        else updatedTasks = [...updatedTasks, task];
      } else {
        task["status"] = "Incomplete";
        updatedTasks = [...updatedTasks, task];
      }
    }

    setTasks(updatedTasks);
    checkTasksOverdue();
    setCompletedTasks(updatedCompletedTasks);
  };

  const isLoadingFetchTasks = isLoading("fetch_tasks");

  useEffect(() => {
    sendRequest({
      key: "fetch_tasks",
      url: config.apiUrl + "/tasks",
      onSuccess: onSuccessFetchTasksData,
    });
  }, []);

  /** Modal events handlers */

  const onCloseTaskModal = () => {
    setIsVisibleTaskModal(false);
    setSelectedTask(null);
  };

  /** TaskList events handlers */

  const taskClickHandler = (task) => {
    setSelectedTask(task);
    setIsVisibleTaskModal(true);
  };

  const doTaskHandler = async (task) => {
    // Call api if the task is not in the user tasks
    // or the tasks is incomplete and overdue.
    if (task.status === "Incomplete") {
      // call an api to server to change task to checking
      // or creating new user task.
      await sendRequest({
        key: "do_task_handler_" + task.id,
        method: "POST",
        url: config.apiUrl + "/tasks",
        data: { task_id: task.id },
        onSuccess: ({ success, data }) => {
          if (success === true) {
            // replace updated task in the tasks list.
            let updatedTask = data.task;
            updatedTask = { ...task, ...updatedTask };
            delete updatedTask.is_overdue;
            setTasks((prevTasks) =>
              prevTasks.map((task) =>
                task.id === updatedTask.id ? updatedTask : task
              )
            );

            setSelectedTask(updatedTask);
            window.location.href = task.link;
          } else
            sendNotification("Operation failed. Try agian later.", "Error");
        },
      });
    } else window.location.href = task.link;
  };

  const onClaimHandler = async (task) => {
    await sendRequest({
      key: "claim_task_" + task.id,
      method: "POST",
      url: config.apiUrl + "/tasks/" + task.id + "/claim",
      onSuccess: async ({ success, data }) => {
        if (success === true) {
          task["status"] = data.status;
          const updatedTask = task;
          if (updatedTask.status === "Claimed") {
            // move to claimed list
            // remove from tasks list.
            // update balance by reward.
            const updatedTasks = tasks.filter((t) => t.id !== updatedTask.id);
            let updatedCompletedTasks = completedTasks.filter(
              (t) => t.id !== updatedTask.id
            );
            updatedCompletedTasks = [...updatedCompletedTasks, updatedTask];
            setTasks(updatedTasks);
            setCompletedTasks(updatedCompletedTasks);
            await updateBalance(() => balance + updatedTask.reward);
            sendNotification("Congratulations, You have done!", "Success");
          } else {
            delete updatedTask.is_overdue;
            delete updatedTask.overdue_at;
            setTasks((prevTasks) =>
              prevTasks.map((t) => (t.id === updatedTask.id ? updatedTask : t))
            );
            sendNotification("The task is not completed yet.", "Info");
          }
        } else
          sendNotification("The operation faield. Try again later.", "Error");

        setIsVisibleTaskModal(false);
        setSelectedTask(null);
      },
    });
  };

  const isTaskOverdue = (task) => {
    // Assuming task.overdue_at is in the format 'Y-m-d H:i:s'
    const overdueAt = moment.utc(task.overdue_at, "YYYY-MM-DD HH:mm:ss");
    const currentTime = moment().utc();
    return currentTime.isAfter(overdueAt);
  };

  const checkTasksOverdue = () => {
    let is_diry = false;
    let taskList = tasks.map((task) => {
      if (
        task.is_overdue !== true &&
        task.status === "Checking" &&
        task.overdue_at !== undefined &&
        isTaskOverdue(task)
      ) {
        task["is_overdue"] = true;
        is_diry = true;
      }
      return task;
    });
    if (is_diry) setTasks(taskList);
  };

  // Check which tasks are overdue every 5 seconds.
  setInterval(checkTasksOverdue, 5000);

  return {
    isVisibleTaskModal,
    tasks,
    completedTasks,
    selectedTask,
    balance,
    notifications,
    isLoadingFetchTasks,
    onCloseTaskModal,
    taskClickHandler,
    doTaskHandler,
    onClaimHandler,
  };
}

export default useTasksScreenHandlers;
