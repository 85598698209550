import React from "react";
import { CiCalendarDate } from "react-icons/ci";

function ReferralItem({ referral }) {
  return (
    <div className="referral-item pb-2 pt-2 w-100 d-flex justify-content-between align-items-center">
      <div className="avatar-container d-flex align-items-center">
        <div className="referral-item-pic">
          <img
            src={`${process.env.PUBLIC_URL}/images/avatar.png`}
            alt="referral-item"
          />
        </div>
        <h5 className="referral-item-name text-white">{referral.username}</h5>
      </div>
      <div className="d-flex align-items-center referral-date text-white">
        <CiCalendarDate className="me-2" />
        <span>{referral.registered_at}</span>
      </div>
    </div>
  );
}

export default ReferralItem;
