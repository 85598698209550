import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.min";
import React, { useContext } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import routes from "./routes";

import PageLoading from "./components/SharedComponents/PageLoading";
import NotificationList from "./components/SharedComponents/Message/NotificationList";
import Screen from "./components/SharedComponents/Screen";

import { BalanceProvider } from "./contexts/BalanceContext";
import { BootstrapContext } from "./contexts/BootstrapContext";

const App = () => {
  const { isLoadingApp, user, notifications } = useContext(BootstrapContext);

  if (isLoadingApp) return <PageLoading />;

  if (user)
    return (
      <BalanceProvider initialBalance={user.balance}>
        <Router>
          <Routes>
            {Object.values(routes).map((route, index) => (
              <Route key={index} path={route.path} element={route.element} />
            ))}
          </Routes>
        </Router>
      </BalanceProvider>
    );
  else
    return (
      <Screen
        style={{
          justifyContent: "center",
        }}
      >
        <NotificationList notifications={notifications} />
        <h1
          style={{
            color: "var(--bg-red)",
          }}
        >
          Loading failed. Try later.
        </h1>
        <img src={`${process.env.PUBLIC_URL}/images/connection-failed.svg`} />
      </Screen>
    );
};

export default App;
