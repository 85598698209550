import { useState, useCallback } from "react";
import axios from "axios";
import config from "../config";

const useApiRequest = (sendNotification) => {
  const [loadingMap, setLoadingMap] = useState({});

  axios.interceptors.response.use(
    (response) => {
      // If the request is successful, just return the response
      return response;
    },
    (error) => {
      if (error.response && error.response.status == 429) {
        if (sendNotification)
          return sendNotification(
            "You tried too many times. Try in other time.",
            "Error"
          );
      }
      return Promise.reject(error);
    }
  );

  const sendRequest = useCallback(
    async ({
      key,
      url,
      method = "GET",
      data = null,
      headers = {},
      beforeRequest,
      onSuccess,
      onError,
    }) => {
      // Check if the request with this key is already in progress
      if (loadingMap[key]) {
        console.warn(`Request for key ${key} is already in progress.`);
        return; // Exit early if request is already in progress
      }

      // Call beforeRequest if provided
      if (beforeRequest) {
        await beforeRequest();
      }

      // Mark this key as loading
      setLoadingMap((prev) => ({ ...prev, [key]: true }));

      try {
        const response = await axios({
          url,
          method,
          data,
          headers: { ...config.apiHeaders, ...headers }, // Merge default and custom headers
        });

        if (onSuccess) {
          onSuccess(response.data);
        }
      } catch (error) {
        if (onError) {
          if (error.response) {
            onError(error.response);
          } else if (error.request) {
            onError({ message: "Network error. Please try again." });
          } else {
            onError(error);
          }
        }
      } finally {
        // Mark this key as not loading
        setLoadingMap((prev) => ({ ...prev, [key]: false }));
      }
    },
    [loadingMap]
  );

  const isLoading = (key) => !!loadingMap[key];

  return { sendRequest, isLoading };
};

export default useApiRequest;
