import { useRef } from "react";

export const useMutex = () => {
  const isLocked = useRef(false);

  const lock = async (fn) => {
    if (isLocked.current) return;

    isLocked.current = true;
    try {
      await fn();
    } finally {
      isLocked.current = false;
    }
  };

  return lock;
};
