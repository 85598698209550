import { useState, useContext, useEffect } from "react";
import config from "../../config";
import axios from "axios";

/** Contexts */
import { BalanceContext } from "../../contexts/BalanceContext";
import { BootstrapContext } from "../../contexts/BootstrapContext";

const useHomeScreenHandlers = () => {
  // get bootstrap data like authenticated user and some another initialization stuff.
  const { user, farming, setFarming } = useContext(BootstrapContext);
  // get balance state.
  const { balance, updateBalance } = useContext(BalanceContext);

  const [name, setName] = useState("");

  /** Handle farming button */
  const farmingButtonHandler = async () => {
    if (["ReadyToClaim", "ReadyToFarm"].includes(farming.state)) {
      const previouseState = farming.state;

      const endpoints = {
        ReadyToClaim: "/farming/claim-reward",
        ReadyToFarm: "/farming/start",
      };

      try {
        const { data: response } = await axios.post(
          config.apiUrl + endpoints[previouseState],
          {},
          {
            headers: config.apiHeaders,
          }
        );

        if (response.success) {
          if (previouseState === "ReadyToClaim")
            await updateBalance(() => balance + response.data.reward);

          setFarming(response.data.farming_state);
        } else {
          // Error handling.
        }
      } catch (error) {
        // Handle request error.
      }
    }
  };

  const farmingFinishedHandler = () => {
    setFarming({
      state: "ReadyToClaim",
      started_at: null,
    });
  };

  /** END Handle farming button */

  useEffect(() => {
    setName(user.name);
  }, []);

  return {
    balance,
    farming,
    name,
    farmingButtonHandler,
    farmingFinishedHandler,
  };
};

export default useHomeScreenHandlers;
