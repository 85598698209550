import React from "react";
import "./TasksScreen.css";
import { BsRadioactive } from "react-icons/bs";
import { RiCheckDoubleFill } from "react-icons/ri";

import AccountBalance from "../../components/AccountBalanceComponent/AccountBalance";
import BottomNavigation from "../../components/BottomNavigationComponent/BottomNavigation";
import TaskList from "../../components/TaskComponent/TaskList";
import TaskModalCard from "../../components/TaskComponent/TaskModalCard";
import Screen from "../../components/SharedComponents/Screen";
import PageLoading from "../../components/SharedComponents/PageLoading";
import NotificationList from "../../components/SharedComponents/Message/NotificationList";
import useNavigations from "../../hooks/useNavigations";
import useTasksScreenHandlers from "./useTasksScreenHandlers";

function TasksScreen() {
  const {
    isVisibleTaskModal,
    tasks,
    completedTasks,
    selectedTask,
    balance,
    notifications,
    isLoadingFetchTasks,
    onCloseTaskModal,
    taskClickHandler,
    doTaskHandler,
    onClaimHandler,
  } = useTasksScreenHandlers();

  const navLinks = useNavigations();

  if (isLoadingFetchTasks) return <PageLoading />;

  return (
    <Screen
      style={{
        padding: 30,
        paddingBottom: 98,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <NotificationList notifications={notifications} />

      <AccountBalance balance={balance} />

      <div className="w-100 tasks-screen">
        <h1 className="text-white text-center w-100">Available tasks</h1>

        <ul
          className="mt-5 nav nav-tabs w-100 d-flex"
          id="myTab"
          role="tablist"
        >
          <li
            key="active"
            className="nav-item w-50 text-center"
            role="presentation"
          >
            <a
              className="nav-link active d-flex justify-content-center align-items-center"
              id="active-tab"
              data-bs-toggle="tab"
              href="#Active"
              role="tab"
              aria-controls="active"
              aria-selected="true"
            >
              <BsRadioactive />
              <span className="ps-2">Active</span>
            </a>
          </li>
          <li
            key="completed"
            className="nav-item w-50 text-center"
            role="presentation"
          >
            <a
              className="nav-link  d-flex justify-content-center align-items-center"
              id="completed-tab"
              data-bs-toggle="tab"
              href="#Completed"
              role="tab"
              aria-controls="completed"
              aria-selected="false"
            >
              <RiCheckDoubleFill />
              <span className="ps-2">Completed</span>
            </a>
          </li>
        </ul>

        <div className="tab-content">
          <div
            className="tab-pane fade show active pt-4 pb-2"
            id="Active"
            role="tabpanel"
            aria-labelledby="active-tab"
          >
            {tasks.length && (
              <TaskList onTaskClickHandler={taskClickHandler} tasks={tasks} />
            )}
            {tasks.length === 0 && (
              <img
                src={`${process.env.PUBLIC_URL}/images/completed-tasks.svg`}
              />
            )}
          </div>
          <div
            className="tab-pane fade  pt-4 pb-2"
            id="Completed"
            role="tabpanel"
            aria-labelledby="completed-tab"
          >
            {completedTasks.length && (
              <TaskList
                onTaskClickHandler={taskClickHandler}
                tasks={completedTasks}
              />
            )}

            {completedTasks.length === 0 && (
              <img
                src={`${process.env.PUBLIC_URL}/images/empty-completed-tasks.svg`}
              />
            )}
          </div>
        </div>
      </div>

      <TaskModalCard
        task={selectedTask}
        onClose={onCloseTaskModal}
        isVisible={isVisibleTaskModal}
        onDoTaskHandler={doTaskHandler}
        onClaimHandler={onClaimHandler}
      />

      <BottomNavigation navLinks={navLinks} />
    </Screen>
  );
}

export default TasksScreen;
