import React from "react";

function CircleActionButton({
  size = 50,
  color = "var(--bg-green)",
  onClickHandler,
  Icon,
  disabled = false,
  style,
}) {
  return (
    <button
      disabled={disabled}
      onClick={onClickHandler}
      className="d-flex justify-content-center align-items-center clickable"
      style={{
        height: size,
        width: size,
        borderRadius: "100%",
        backgroundColor: color,
        color: "#fff",
        boxShadow: "none",
        border: "none",
        ...style,
      }}
    >
      {Icon}
    </button>
  );
}

export default CircleActionButton;
