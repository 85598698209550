import React from "react";

function BottomNavigation({ navLinks }) {
  return (
    <div
      className="p-2 d-flex align-items-center justify-content-around"
      style={{
        bottom: "1rem",
        height: 58,
        position: "absolute",
        borderRadius: 25,
        left: "1rem",
        width: "calc(100% - 2rem)",
        backgroundColor: "var(--bg-dark-light)",
      }}
    >
      {navLinks.map((navLink) => {
        return (
          <span
            onClick={navLink.onClickHandler}
            key={navLink.title}
            className="text-center clickable"
            style={{ color: "var(--bg-yellow)" }}
          >
            <span style={{ fontSize: 20 }}>{navLink.icon}</span>
            <span className="d-block mt-1 fw-bold" style={{ fontSize: 12 }}>
              {navLink.title}
            </span>
          </span>
        );
      })}
    </div>
  );
}

export default BottomNavigation;
