const config = {
  apiUrl: "https://testv234.archeoam.com/api",
  initUrl: "https://testv234.archeoam.com/sanctum/csrf-cookie",
  apiHeaders: {
    "Content-Type": "application/json",
    Accept: "application/json",
  },
};

export default config;
