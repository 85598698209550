import React, { createContext, useEffect, useState } from "react";
import axios from "axios";
import WebApp from "@twa-dev/sdk";
import config from "../config";

import useApiRequest from "../hooks/useApiRequest";
import useNotifications from "../hooks/useNotifications";

export const BootstrapContext = createContext();

export const BootstrapProvider = ({ children }) => {
  const [user, setUser] = useState({});
  // define farming state.
  const [farming, setFarming] = useState({
    state: "Loading",
    started_at: null,
  });

  const { notifications, sendNotification } = useNotifications();
  const { isLoading, sendRequest } = useApiRequest(sendNotification);

  /** fetchUserData */
  // Initialize the SDK
  WebApp.ready();
  const initData = WebApp.initDataUnsafe;

  const {
    first_name = "",
    last_name = "",
    id: telegram_id = "",
    username = "",
  } = initData.user || {}; // Fallback to an empty object if initData.user is undefined

  // Construct loginData object with nullish coalescing for referral_code
  const loginData = {
    first_name,
    last_name,
    username,
    telegram_id,
    referral_code: initData.start_param ?? null, // Use null if start_param is undefined or null
  };

  useEffect(() => {
    console.log("try to login");
    sendRequest({
      key: "login",
      method: "POST",
      url: config.apiUrl + "/account/login",
      data: loginData,
      beforeRequest: async () => {
        axios.defaults.withCredentials = true;
        axios.defaults.withXSRFToken = true;
        await axios.get(config.initUrl);
      },
      onSuccess: ({ success, data }) => {
        if (success === true) {
          setUser({
            balance: data.balance,
            name: data.user.name,
          });
          setFarming(data.farming_state);
        } else {
          setUser(null);
          sendNotification("Loading app faliled. Try again later.", "Error");
        }
      },
      onError: () => setUser(null),
    });
  }, []);

  const isLoadingApp = isLoading("login");
  return (
    <BootstrapContext.Provider
      value={{ user, isLoadingApp, farming, notifications, setFarming }}
    >
      {children}
    </BootstrapContext.Provider>
  );

  /** End fetchUserData */
};
