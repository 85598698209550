import React, { createContext, useState, useEffect } from "react";
import { useMutex } from "../hooks/useMutex";

export const BalanceContext = createContext();

export const BalanceProvider = ({ children, initialBalance = 0 }) => {
  const [balance, setBalance] = useState(initialBalance);
  const lock = useMutex();

  // Every fn must be return a response which contains
  // success as boolean and a data object which contains update balance.
  const updateBalance = async (getNewBalance) => {
    await lock(async () => {
      const newBalance = await getNewBalance();
      setBalance(newBalance);
    });
  };

  return (
    <BalanceContext.Provider value={{ balance, updateBalance }}>
      {children}
    </BalanceContext.Provider>
  );
};
