import React from "react";
import "./Notification.css";

import Notification from "./Notification";

function NotificationList({ notifications }) {
  return (
    <div
      style={{
        position: "absolute",
        top: 0,
        left: 0,
        maxHeight: "30%",
        overflow: "hidden",
        width: "100%",
        padding: "2rem 5%",
      }}
      className="d-flex flex-column"
    >
      {notifications.map((notification, index) => (
        <Notification key={index} notification={notification} />
      ))}
    </div>
  );
}

export default NotificationList;
