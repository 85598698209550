import React from "react";
import TaskItem from "./TaskItem";

function TaskList({ tasks, onTaskClickHandler }) {
  return (
    <div>
      {tasks.map((task) => {
        return (
          <TaskItem
            key={task.id}
            onClickHandler={onTaskClickHandler}
            task={task}
          />
        );
      })}
    </div>
  );
}

export default TaskList;
