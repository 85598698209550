import React from "react";

import AccountBalance from "../../components/AccountBalanceComponent/AccountBalance";
import BottomNavigation from "../../components/BottomNavigationComponent/BottomNavigation";
import ReferralList from "../../components/ReferralComponent/ReferralList";
import PageLoading from "../../components/SharedComponents/PageLoading";
import Screen from "../../components/SharedComponents/Screen";
import TextActionButton from "../../components/SharedComponents/TextActionButton/TextActionButton";
import NotificationList from "../../components/SharedComponents/Message/NotificationList";

import useNavigations from "../../hooks/useNavigations";
import useReferralScreenHandlers from "./useReferralScreenHandlers";

function ReferralScreen() {
  const {
    referrals,
    referralBalance,
    balance,
    notifications,
    isloadingReferrals,
    onClaimHandler,
  } = useReferralScreenHandlers();

  const navLinks = useNavigations();

  if (isloadingReferrals) return <PageLoading />;

  return (
    <Screen
      style={{
        padding: 30,
        paddingBottom: 98,
        overflow: "hidden",
        position: "relative",
      }}
    >
      <NotificationList notifications={notifications} />

      <AccountBalance balance={balance} />

      {referralBalance > 0 && (
        <div className="w-100 mb-3 mt-3">
          <TextActionButton
            onClick={onClaimHandler}
            title={"$ " + referralBalance}
            buttonTitle="Claim"
          />
        </div>
      )}

      <div className="w-100 mb-3 mt-3">
        {referrals.length && <ReferralList referrals={referrals} />}

        {referrals.length === 0 && (
          <img src={`${process.env.PUBLIC_URL}/images/referrals-empty.svg`} />
        )}
      </div>
      <BottomNavigation navLinks={navLinks} />
    </Screen>
  );
}

export default ReferralScreen;
