import React, { useState } from "react";
import { FaCheckCircle, FaExclamationCircle } from "react-icons/fa";

const Notification = ({ notification }) => {
  /** States */
  const [animatingOut, setAnimatingOut] = useState(false);

  const messageIcons = {
    Error: <FaExclamationCircle size={20} className="notification-icon" />,
    Success: <FaCheckCircle size={20} className="notification-icon" />,
    Info: <FaExclamationCircle size={20} className="notification-icon" />,
  };

  const notificationContainerClasses =
    "notification-container animate__animated animate__bounceInLeft notification-" +
    notification.type;

  return (
    <div className={notificationContainerClasses}>
      <div className="notification-icon-container">
        {messageIcons[notification.type]}
      </div>
      <div className="notification-text">
        <span>{notification.text}</span>
      </div>
    </div>
  );
};

export default Notification;
