import React from "react";

function Screen({ children, style }) {
  return (
    <main
      className="d-flex align-items-center flex-column"
      style={{
        padding: "1rem",
        backgroundColor: "var(--bg-dark)",
        height: "100vh",
        width: "100vw",
        overflowY: "scroll",
        ...style,
      }}
    >
      {children}
    </main>
  );
}

export default Screen;
