import { useState, useCallback } from "react";

const useNotifications = () => {
  const [notifications, setnotifications] = useState([]);

  const sendNotification = useCallback((text, type) => {
    const id = Date.now();
    setnotifications((prevNotifications) => [
      ...prevNotifications,
      { id, text, type },
    ]);

    // Automatically remove the notification after 5 seconds
    const timer = setTimeout(() => {
      setnotifications((prevNotifications) =>
        prevNotifications.filter((notification) => notification.id !== id)
      );
    }, 5000);

    return () => clearTimeout(timer);
  }, []);

  return { notifications, sendNotification };
};

export default useNotifications;
