import React from "react";
import "./Tasks.css";
import { BsTwitterX } from "react-icons/bs";
import { FiInstagram, FiYoutube } from "react-icons/fi";
import { LiaTelegram } from "react-icons/lia";

function TaskItem({ task, onClickHandler }) {
  const icons = {
    JoinTelegramChannel: <LiaTelegram />,
    JoinTelegramGroup: <LiaTelegram />,
    FollowInstagram: <FiInstagram />,
    FollowTwitter: <BsTwitterX />,
    WatchYoutubeVideo: <FiYoutube />,
  };

  return (
    <div
      onClick={() => onClickHandler(task)}
      className="task-item d-flex align-items-center clickable"
    >
      <span className="task-icon rounded-circle">{icons[task.task_type]}</span>
      <div className="ps-2 task-title flex-grow-1">
        <span className="d-block w-100 mb-1">{task.title}</span>
        <span className="d-block task-reward w-100">+${task.reward}</span>
      </div>
      <span className={"task-button task-" + task.status}>{task.status}</span>
    </div>
  );
}

export default TaskItem;
