import React from "react";
import "./Referral.css";
import ReferralItem from "./ReferralItem";

function ReferralList({ referrals }) {
  return (
    <div className="d-flex w-100 flex-column align-items-start">
      {referrals.map((referral, index) => (
        <ReferralItem key={index} referral={referral} />
      ))}
    </div>
  );
}

export default ReferralList;
