import React from "react";
import { FaGift, FaRegArrowAltCircleRight } from "react-icons/fa";
import { FaArrowsTurnToDots } from "react-icons/fa6";

import ActionButton from "../SharedComponents/ActionButton";
import ModalCard from "../SharedComponents/ModalCard/ModalCard";
import CircleActionButton from "../SharedComponents/CircleActionButton/CircleActionButton";

function TaskModalCard({
  task,
  onClaimHandler,
  onDoTaskHandler,
  isVisible,
  onClose,
}) {
  const isEnabledClaimButton =
    task &&
    (task.status === "Completed" ||
      (task.status === "Checking" && task.is_overdue === true));

  return (
    <ModalCard isVisible={isVisible} onClose={onClose}>
      {task && (
        <div className="task-modal-card  d-flex align-items-center flex-column">
          <h2 className="text-white text-center w-100 mb-4">{task.title}</h2>

          <div className="d-flex w-100 justify-content-around  mb-3">
            <span className="details-widget">
              <span className="details-widget-title">Reward</span>
              <span className="d-flex align-items-baseline details-widget-value">
                <FaGift className="me-2" />
                +${task.reward}
              </span>
            </span>

            <span className="details-widget">
              <span className="details-widget-title">Status</span>
              <span className="d-flex align-items-baseline details-widget-value">
                <FaArrowsTurnToDots className="me-2" />
                {task.status}
              </span>
            </span>
          </div>

          <div
            style={{
              borderRadius: 25,
              backgroundColor: "var(--bg-dark-light)",
            }}
            className="w-100 d-flex p-1"
          >
            <ActionButton
              style={{ flexGrow: 1, padding: "0 1rem", marginRight: ".5rem" }}
              titleStyle={{ fontSize: "1rem", textAlign: "center" }}
              title="Click to do task"
              onClickHandler={() => onDoTaskHandler(task)}
            />

            <CircleActionButton
              onClickHandler={() => onClaimHandler(task)}
              color="var(--bg-yellow)"
              style={{
                color: "var(--bg-dark-light)",
              }}
              size={40}
              disabled={!isEnabledClaimButton}
              Icon={<FaGift />}
            />
          </div>
        </div>
      )}
    </ModalCard>
  );
}

export default TaskModalCard;
