import React, { useEffect, useState } from "react";
import "./FarmingButton.css";
import moment from "moment";
import { IoSyncCircleOutline } from "react-icons/io5";

function FarmingButton({ farming, onFinished, onClickHandler }) {
  const [counter, setCounter] = useState("00:00");
  const [progressbarPercent, setProgressbarPercent] = useState(0);
  const [isFinishedCounter, setIsFinishedCounter] = useState(false);

  // Get remaining time in UTC
  const getRemainingTime = (endDate) => {
    const now = moment().utc();
    const remainingTime = endDate.diff(now);

    if (remainingTime <= 0) {
      setIsFinishedCounter(true);
      return "00:00";
    }

    const duration = moment.duration(remainingTime);
    const hours = String(Math.floor(duration.asHours())).padStart(2, "0");
    const minutes = String(duration.minutes()).padStart(2, "0");

    return `${hours}:${minutes}`;
  };

  // Calculate elapsed percentage
  const getElapsedPercentage = (startDate, endDate) => {
    const now = moment().utc();
    const totalDuration = endDate.diff(startDate);
    const elapsedDuration = now.diff(startDate);

    const percentage = Math.min((elapsedDuration / totalDuration) * 100, 100);
    return percentage.toFixed(2); // Limit to two decimal places
  };

  // Set class names
  let farmingButtonClassNames = "farming-button " + farming.state;
  if (["ReadyToClaim", "ReadyToFarm"].includes(farming.state))
    farmingButtonClassNames += " clickable ";

  useEffect(() => {
    if (farming.started_at) {
      const startDate = moment.utc(farming.started_at, "YYYY-MM-DD HH:mm:ss");
      const endDate = moment(startDate).add(24, "hours");
      const updateCounter = () => {
        setCounter(getRemainingTime(endDate));
        setProgressbarPercent(getElapsedPercentage(startDate, endDate));
        if (isFinishedCounter) onFinished();
      };
      updateCounter();

      const intervalId = setInterval(updateCounter, 60000);

      // Cleanup function to clear the interval when the component unmounts
      return () => clearInterval(intervalId);
    }
  }, [farming, isFinishedCounter]);

  return (
    <div onClick={onClickHandler} className={farmingButtonClassNames}>
      {farming.state == "Farming" && (
        <div
          style={{ width: progressbarPercent + "%" }}
          id="farming-button-progreebar"
        ></div>
      )}
      <div className="d-flex align-items-center">
        <IoSyncCircleOutline
          className={farming.state == "Farming" ? "rotating-icon" : ""}
          id="farming-button-icon"
        />
        <span className="ms-2">{farming.state}</span>
      </div>
      {farming.state == "Farming" && <div className="pe-2 ps-2">{counter}</div>}
    </div>
  );
}

export default FarmingButton;
