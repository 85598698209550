import { useNavigate } from "react-router-dom";
import { routePath } from "../routes";

/** Icons */
import { IoHomeOutline, IoWalletOutline } from "react-icons/io5";
import { GoTasklist } from "react-icons/go";
import { TbUsersGroup } from "react-icons/tb";
import { PiCoinsLight } from "react-icons/pi";

function useNavigations() {
  const navigate = useNavigate();
  const navLinks = [
    {
      icon: <IoHomeOutline />,
      title: "Home",
      onClickHandler: () => navigate(routePath.home),
    },
    {
      icon: <GoTasklist />,
      title: "Tasks",
      onClickHandler: () => navigate(routePath.tasks),
    },
    {
      icon: <TbUsersGroup />,
      title: "Friends",
      onClickHandler: () => navigate(routePath.friends),
    },
    {
      icon: <PiCoinsLight />,
      title: "Staking",
      onClickHandler: () => navigate(routePath.staking),
    },
    {
      icon: <IoWalletOutline />,
      title: "Wallet",
      onClickHandler: () => navigate(routePath.wallet),
    },
  ];

  return navLinks;
}

export default useNavigations;
