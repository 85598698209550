import React from "react";
import Screen from "./Screen";

function PageLoading(props) {
  return (
    <Screen
      style={{
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <div className="spinner-grow text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </Screen>
  );
}

export default PageLoading;
