import React from "react";
import { PiCoinsDuotone } from "react-icons/pi";

function AccountBalance({ balance }) {
  return (
    <h1
      className="w-100 fw-bold  text-center"
      style={{ fontSize: 45, marginTop: 34, color: "var(--bg-yellow)" }}
    >
      <PiCoinsDuotone className="pe-2" />
      {balance.toLocaleString()}
    </h1>
  );
}

export default AccountBalance;
