import { useContext, useEffect, useState } from "react";
import config from "../../config";

import { BalanceContext } from "../../contexts/BalanceContext";
import useApiRequest from "../../hooks/useApiRequest";
import useNotifications from "../../hooks/useNotifications";

function useReferralScreenHandlers() {
  const [referrals, setReferrals] = useState([]);
  const [referralBalance, setReferralBalance] = useState(0);
  const { updateBalance, balance } = useContext(BalanceContext);
  const { isLoading, sendRequest } = useApiRequest();
  const { notifications, sendNotification } = useNotifications();

  /** fetchReferralsData */
  useEffect(() => {
    sendRequest({
      key: "fetch_referrals",
      url: config.apiUrl + "/referrals",
      onSuccess: async ({ success, data }) => {
        if (success === true) {
          setReferralBalance(data.referral_balance);
          setReferrals(data.referrals);
        } else
          sendNotification(
            "Something went wrong. Page loading failed.",
            "Error"
          );
      },
    });
  }, []);

  /** Modal events handlers */

  /** Referra events handlers */

  const onClaimHandler = async () => {
    if (referralBalance > 0) {
      sendRequest({
        key: "claim_referral_reward",
        method: "POST",
        url: config.apiUrl + "/referrals/claim-reward",
        onSuccess: async ({ success }) => {
          if (success === true) {
            const newBalance = balance + parseFloat(referralBalance);
            await updateBalance(() => newBalance);
            setReferralBalance(0);
          } else {
            sendNotification(
              "Something went wrong. Page loading failed.",
              "Error"
            );
          }
        },
      });
    }
  };

  const isloadingReferrals = isLoading("fetch_referrals");

  return {
    referrals,
    referralBalance,
    balance,
    notifications,
    isloadingReferrals,
    onClaimHandler,
  };
}

export default useReferralScreenHandlers;
