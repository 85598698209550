import React from "react";
import { IoArrowForward } from "react-icons/io5";
import "./TextActionButton.css";

const TextActionButton = ({ title, buttonTitle = "", onClick, Icon }) => {
  return (
    <div className="text-action-button-container">
      <span className="text-action-button-text">{title}</span>
      <span
        onClick={onClick}
        className="text-action-button-button circle-rounded"
      >
        <span className="clickable">{buttonTitle}</span>
        {Icon || <IoArrowForward />}
      </span>
    </div>
  );
};

export default TextActionButton;
